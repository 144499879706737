<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
            type="button"
            @click="goBack()"
            class="btn btn-inverse"
            title="Kembali"
          >
            Kembali
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Jabatan</small>
            <h6>{{ state.detail.name === "" ? "-" : state.detail.name }}</h6>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted p-t-30 db">Unit Penerima</small>
            <h6>
              {{
                state.detail.unitPos.text === ""
                  ? "-"
                  : state.detail.unitPos.text
              }}
            </h6>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted p-t-30 db">Deskripsi</small>
            <h6>{{state.detail.description === ""? '-' : state.detail.description}}</h6>
            <small class="text-muted p-t-30 db">Submitted</small>
            <h6>{{submitted}}</h6>
            <small class="text-muted p-t-30 db">Modified</small>
            <h6>{{modified}}</h6>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
// import ImageItem from "../../../components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    // ImageItem
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.jabatan;
    },
    // tanggalditerima() {
    //   return this.formatDate(this.state.detail.tanggal_diterima);
    // },
    // tanggaldokumen() {
    //   return this.formatDate(this.state.detail.tanggal_dokumen);
    // },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("jabatan/STATE", state);
      this.$store.dispatch("jabatan/getJabatanById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("jabatan/onCancel");
    },
  },
};
</script>
